import axios from 'axios'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import {BASE_URL} from './config/index'
import {getToken} from '../utils/auth'

const instance = new axios.create({
  baseURL:BASE_URL,
  timeout:5000
});

// 请求拦截器
instance.interceptors.request.use(config => {
  //拦截后需要将拦截下来的请求数据返回发送
  NProgress.start();
  return config;
}, err => {
  NProgress.done();
  console.log(err)
})

// 相应拦截器
instance.interceptors.response.use(config => {
  // 拦截后需要将拦截下来处理成的结果返回
  NProgress.done();
  return config
}, err => {
  NProgress.done();
  console.log(err)
})

export function headersToken() {
  instance.defaults.headers.common['token'] = getToken();
}

export function get(url,params){
  return instance.get(url,{
    params
  })
}

export function post(url,data){
  return instance.post(url,data)
}
<template>
    <div class="page">
        <div>
            <Header />
        </div>
        <!-- 路由占位符 -->
        <router-view></router-view>
    </div>
</template>

<script>
import Header from '../components/hearder'
export default {
    name:'page',
    components: {
        Header
    }
}
</script>

<style scoped>
.page {
    width: 100%;
    /* height: 100%; */
}
</style>
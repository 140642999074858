// 定义接口跟路径
export default {
    mock:{
        name: "虚拟环境接口",
        url: "https://index.nnhpiot.com"
    },
    dev:{
        name: "开发环境接口",
        url: "https://index.nnhpiot.com"
    },
    pro:{
        name:"生成环境接口",
        url:"https://index.nnhpiot.com"
    },
    test:{
        name:"测试环境接口",
        // url:"http://stopup.top:39002"
        url: "https://index.nnhpiot.com"
    }
}
<template>
  <!-- 头部 -->
  <div class="top">
      <!-- 导航条 -->
      <div class="el-menu-demo">
        <el-menu router :default-active="defaultUrl" mode="horizontal">
            <el-menu-item index="0">
              <img :src="url" alt="" style="width:150px; height:65px"/>
            </el-menu-item>
            <el-menu-item index="/home" @click="goto('/home')" v-if="this.data[1]">{{data[1].moduleName}}</el-menu-item>
            <el-menu-item index="/introduce/1422554758010630146" @click="goto('/introduce/' + data[2].id)" v-if="this.data[1]">{{data[2].moduleName}}</el-menu-item>
            <el-menu-item index="/dynamic/1420955621200633858" @click="goto('/dynamic/' + data[0].id)" v-if="this.data[1]">{{data[0].moduleName}}</el-menu-item>
            <el-menu-item index="/product/1422555056364056578" @click="goto('/product/' + data[3].id)" v-if="this.data[1]">{{data[3].moduleName}}</el-menu-item>
            <el-submenu index='/Online_tools'>
                <template slot="title" v-if="this.data[1]">{{data[4].moduleName}}</template>
                <el-menu-item index='/Online_tools' @click="getExtern(item.id)"  v-for="(item,i) in data1" :key="i">{{item.moduleName}}</el-menu-item>
                <el-menu-item index="/Online_tools" @click="goto('/Online_tools')">在线工具</el-menu-item>
                <el-menu-item index="/cardinfo" @click="goto('/cardinfo')">SIM卡查询</el-menu-item>
                <!-- <el-menu-item index="5-2">API文档</el-menu-item>
                <el-menu-item index="5-3">论坛</el-menu-item> -->
            </el-submenu>
            <el-menu-item index="/solve/1422555415560056833" @click="goto('/solve/' + data[5].id)" v-if="this.data[1]">{{data[5].moduleName}}</el-menu-item>
            <el-menu-item index="/contactus" @click="goto('/contactus')" v-if="this.data[1]">{{data[6].moduleName}}</el-menu-item>

            <el-menu-item style="border-bottom:none;" v-if="isshow">
              <el-button type="primary" @click="goto('/login')">登录</el-button>
              <el-button type="warning" @click="goto('/register')" class="register"> 快速注册 </el-button>
            </el-menu-item>
            <el-menu-item index="/" v-if="!isshow">
              <!-- <el-link target="_blank" @click="logout" class="register"> 退出登录 </el-link> -->
              <el-col :span="12">
                <el-dropdown placement="bottom">
                  <span class="el-dropdown-link">
                    <img src="../assets/bg1.jpg" alt=""/>
                    <!-- 下拉菜单<i class="el-icon-arrow-down el-icon--right"></i> -->
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-s-tools">设置</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-user-solid">个人中心</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-circle-plus-outline">阿巴阿巴</el-dropdown-item>
                    <el-dropdown-item @click.native="logout" icon="el-icon-s-promotion">退出登录</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-col>
            </el-menu-item>
            <el-menu-item style="border-bottom:none;" :index="this.$route.path"><el-button type="success" @click="go">控制台</el-button></el-menu-item>
          </el-menu>
      </div>
  </div>
</template>
<script>
import {post,get,headersToken} from '../network/request'
import {BASE_URL} from '../network/config/index'
import { getToken } from '../utils/auth'
export default {
    data() {
        return {
          isshow:true,
          defaultUrl:'/home',
          data:[],
          data1:[],
          url:'',
          
        }
    },
    watch: {
      '$route':'getPath'
    },
    created(){
      headersToken()
      console.log(getToken());
      if(getToken())
      {
        this.isshow = false;
      }
      console.log(this.data);
      // this.getlogo()
      // this.getmoblue()
    },
    mounted(){
      let href = window.location.href;
      this.defaultUrl = href.split('/#')[1]
      console.log(this.defaultUrl);
      this.getlogo()
      this.getmoblue()
    },
    methods: {
      getPath(){
      this.defaultUrl = this.$route.path;
      console.log(this.defaultUrl);
    },
      // handleSelect(data){
      //   this.$router.push({ path: data })
      // },
      go(){
        window.open('https://www.nnhpiot.com/main/#/dashboard')
        
      },
      getExtern(id){
        get('/module/sys-module-description/getExtern/'+ id,{

        }).then(res=>{
          console.log(res);
          if(res.data.code == 200) {
            // window.location.href = res.data.data
            window.open(res.data.data)
          }
        }).catch(err=>{
          console.log(err);
        })
      },
      gettt(){
        // console.log("123")
      },
      goto(url){
        this.$router.push(url)
      },
      logout(){
        localStorage.removeItem('token');
        this.isshow = true;
        // this.$router.push('/login')
      },
      getmoblue(){
        get('/module/sys-module/getAllModuleWithUser',{

        }).then(res=>{
          // console.log(res);
          if(res.data.code == 200) {
            this.data = res.data.data[0].children
            this.data1 = this.data[4].children
            console.log(this.data);
            
          }
        }).catch(err=>{
          console.log(err);
        })
      },
      getlogo(){
        get('/logo/sys-logo/get',{

        }).then(res=>{
          // console.log(res);
          if(res.data.code == 200) {
            this.url =BASE_URL+'/'+ res.data.data.logoUrl
            // console.log(this.url);
          }
        }).catch(err=>{
          // console.log(err);
        })
      }

    }
}
</script>

<style lang="less" scoped>
  .top{
    height:65px;
    width:100%;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .el-menu-demo{
    width: 100%;
    height: 65px;
    display: flex;
    justify-content: center;
   }
  //  .el-menu-item img{
  //    width: 50px;
  //    height: 50px;
  //  }
  .el-dropdown-link {
    cursor: pointer;
    color: #409EFF;
    // width: 50px;
    // height: 50px;
    // border: 1px solid #409EFF;
    
  }
  .el-dropdown-link img{
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  .el-icon-arrow-down {
    font-size: 12px;
  }
  .demonstration {
    display: block;
    color: #8492a6;
    font-size: 14px;
    margin-bottom: 20px;
  }
</style>